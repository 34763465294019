import React, {useState} from 'react';

import {Card, Col, Flex, Row} from "antd";
import {Fade} from "react-awesome-reveal";





const Section3Mobile = (props) => {

    const [showWebDes, setShowWebDesign] = useState(false);
    const [showWebDev, setShowWebDev] = useState(true);

    return (
        <div className="container">
            <Flex gap={40} id="services" className="services_mobile" data-cursor-exclusion>
                <div className="title_three_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                    <Fade delay={0} cascade damping={0.05} style={{borderBottom:`2px solid ${props.textColor}`,transition:'1s all ease'}}>
                        Услуги
                    </Fade>
                </div>
                <div className="wrapper_btn_services">
                    <button
                        style={{
                            opacity:`${showWebDev === true ? '1' : '.7'}`,
                            background:`${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                            color:`${props.textColor === '#fff' ? '#292929' : '#fff'}`
                        }}
                        onClick={() => {
                            setShowWebDesign(false);
                            setShowWebDev(true);
                        }}
                    >Разработка</button>
                    <button
                        style={{
                            opacity:`${showWebDes === true ? '1' : '.7'}`,
                            background:`${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                            color:`${props.textColor === '#fff' ? '#292929' : '#fff'}`,
                        }}
                        onClick={() => {
                            setShowWebDesign(true);
                            setShowWebDev(false);
                        }}
                    >Веб-дизайн</button>
                </div>
                {showWebDev &&
                    <div>
                        {props.cards.map(el => (
                            <Card
                                style={{
                                    position: 'relative', // Важно для позиционирования видео внутри карточки
                                    borderRadius: '25px 0 25px',
                                    overflow: 'hidden',
                                    background: '#000'// Чтобы видео не выходило за границы карточки
                                }}
                                hoverable
                            >
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    playsInline // Важно для мобильных устройств, чтобы не открывать на весь экран
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover", // Заполнить карточку, сохраняя пропорции видео
                                        zIndex: 0,
                                        opacity: .7 // Разместить видео позади содержимого карточки
                                    }}
                                >
                                    <source src={el.video} type="video/mp4" />
                                </video>
                                <div className="card_uslug_mobile">
                                    <div className="titleCardMobile"
                                         style={{
                                             background: `${props.bgColor}`,
                                             color: `${props.textColor}`
                                         }}
                                    >
                                        {el.name}
                                    </div>
                                    <div className="descCardMobile"
                                         style={{
                                             color: `#fff`
                                         }}
                                    >
                                        {el.description}
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                }
                {showWebDes &&
                    <div>
                        {props.cardsBottom.map(el => (
                            <Card
                                style={{
                                    position: 'relative', // Важно для позиционирования видео внутри карточки
                                    borderRadius: '25px 0 25px',
                                    overflow: 'hidden',// Чтобы видео не выходило за границы карточки
                                    background: '#000'
                                }}
                                hoverable
                            >
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    playsInline // Важно для мобильных устройств, чтобы не открывать на весь экран
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover", // Заполнить карточку, сохраняя пропорции видео
                                        zIndex: 0, // Разместить видео позади содержимого карточки,
                                        opacity: .7
                                    }}
                                >
                                    <source src={el.video} type="video/mp4" />
                                </video>
                                <div className="card_uslug_mobile">
                                    <div className="titleCardMobile"
                                         style={{
                                             background: `${props.bgColor}`,
                                             color: `${props.textColor}`
                                         }}
                                    >
                                        {el.name}
                                    </div>
                                    <div className="descCardMobile"
                                         style={{
                                             color: `#fff`
                                         }}
                                    >
                                        {el.description}
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                }


                <span className="price_calc_text"
                      style={{
                          color:`${props.textColor}`
                      }}
                >
                Цены в зависимости от сложности могут варьироваться, пожалуйста, оставьте заявку и мы рассчитаем стоимость Вашего проекта.
            </span>
            </Flex>


        </div>

    );
};

export default Section3Mobile;