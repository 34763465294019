import React, {useState} from 'react';
import {Fade} from "react-awesome-reveal";
import {Collapse, Space} from 'antd';
import './portfolio.css';
import '../section4/section4.css';
import {CaretRightOutlined, CheckCircleFilled} from "@ant-design/icons";

const Portfolio = (props) => {







    return (

        <div id='steps' className="block_thour_section" data-cursor-exclusion>
            <div className="title_thour_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                <Fade delay={0} cascade damping={0.05} style={{borderBottom:`2px solid ${props.textColor}`,transition:'1s all ease'}}>
                    Этапы
                </Fade>
            </div>
            <div className="container">
                {/*<Steps current={current} items={items} />*/}
                {/*<Fade>*/}
                {/*<div style={{color:'#fff',*/}
                {/*    height: '300px',*/}
                {/*    display: 'flex',*/}
                {/*    alignItems: 'center',*/}
                {/*    background: '#010716',*/}
                {/*    justifyContent: 'center',*/}
                {/*    margin: '20px',*/}
                {/*    borderRadius: '20px',*/}
                {/*padding:'20px'}}>{steps[current].content}</div>*/}
                {/*<div*/}
                {/*    style={{*/}
                {/*        marginTop: 24,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {current > 0 && (*/}
                {/*        <Button*/}
                {/*            style={{*/}
                {/*                margin: '0 8px',*/}
                {/*            }}*/}
                {/*            icon={<CaretLeftFilled />}*/}
                {/*            onClick={() => prev()}*/}
                {/*        >*/}
                {/*        </Button>*/}
                {/*    )}*/}
                {/*    {current < steps.length - 1 && (*/}
                {/*        <Button icon={<CaretRightFilled />} onClick={() => next()}>*/}
                {/*        </Button>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*</Fade>*/}
                <Space
                    style={{width:'100%'}}
                    direction="vertical">
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '1',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Интервью и сбор требований</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">На этом этапе нам необходимо понять потребности, бизнес-задачи и ожидания от сайта.</div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                    <span className="name_list_steps">
                                                        <CheckCircleFilled />
                                                        <span> Определение целей и задач:</span>
                                                    </span>
                                                    Какую ценность должен приносить сайт и каких целей Вы желаете достичь.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                    <span className="name_list_steps">
                                                        <CheckCircleFilled />
                                                        <span> Анализ целевой аудитории:</span>
                                                    </span>
                                                    Понять, кто будет основными пользователями сайта, какие их потребности и предпочтения, как они обычно взаимодействуют с сайтами.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                    <span className="name_list_steps">
                                                        <CheckCircleFilled />
                                                        <span> Сбор требований к функциональности:</span>
                                                    </span>
                                                    Определяем необходимые функции и разделы сайта (блог, форма обратной связи, личный кабинет и пр.).
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                    <span className="name_list_steps">
                                                        <CheckCircleFilled />
                                                        <span> Определение стиля и бренд-ориентированного дизайна:</span>
                                                    </span>
                                                    Обсуждаем предпочтения в дизайне, цветовые решения, примеры сайтов, которые Вам нравятся.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                    <span className="name_list_steps">
                                                        <CheckCircleFilled />
                                                        <span> Итог:</span>
                                                    </span>
                                                    Документ с требованиями (техническое задание, ТЗ), который согласуется с Вами и утверждается.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                                // Стиль контента панели
                            },
                        ]}
                        style={{background:'transparent'}}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '2',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Исследование и анализ конкурентов
</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Этот этап позволяет понять, как работают Ваши конкуренты, какие у них сильные и слабые стороны, чтобы сделать сайт лучше.</div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Анализ сайтов конкурентов:</span>
                                                        </span>
                                                    Изучаем, как построены сайты конкурентов, какие функции и разделы они используют, и как они взаимодействуют с пользователями.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Анализ целевой аудитории:</span>
                                                        </span>
                                                    Понять, кто будет основными пользователями сайта, какие их потребности и предпочтения, как они обычно взаимодействуют с сайтами.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Сбор идей и лучших практик:</span>
                                                        </span>
                                                    Ищем наиболее удачные решения, которые можно адаптировать для будущего сайта.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог:</span>
                                                        </span>
                                                    Отчёт о конкурентах и рекомендации, что можно улучшить в проекте.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '3',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Дизайн</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">На этом этапе создается полноценный визуальный дизайн в Figma.</div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Создание уникального дизайна:</span>
                                                        </span>
                                                    Разработка дизайна с учетом Ваших предпочтений и бренд-стиля.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Работа над адаптивностью:</span>
                                                        </span>
                                                    Создание версий дизайна для мобильных, планшетных и настольных устройств.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Подбор визуальных элементов:</span>
                                                        </span>
                                                    Выбор изображений, иконок, шрифтов и цветовых решений, которые соответствуют стилю и усиливают восприятие контента.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Согласование с Вами:</span>
                                                        </span>
                                                    Презентация дизайна, внесение правок и утверждение итогового варианта.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог:</span>
                                                        </span>
                                                    Утверждённые макеты всех страниц в разных разрешениях.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '4',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Верстка</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Превращение дизайна в HTML, CSS и JavaScript-код, чтобы он отображался в браузере.
                                        </div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> HTML/CSS-верстка:</span>
                                                        </span>
                                                    Создание структуры страницы и стилей для каждого элемента.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Адаптивная верстка:</span>
                                                        </span>
                                                    Оптимизация под разные устройства и разрешения экрана.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Добавление интерактивных элементов:</span>
                                                        </span>
                                                    Реализация анимаций, эффектов при наведении, кнопок, форм и других интерактивных элементов.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог:</span>
                                                        </span>
                                                    Полностью сверстанный интерфейс, который выглядит так же, как утверждённый дизайн.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '5',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Разработка</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Подключение backend-функционала, который отвечает за логику и обработку данных на сайте.
                                        </div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Подключение CMS или создание административной панели (если это требуется):</span>
                                                        </span>
                                                    Настройка системы управления контентом (WordPress, MODX, Drupal) или разработка кастомной панели для добавления и редактирования контента.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Реализация функциональности:</span>
                                                        </span>
                                                    Создание основных функций, поиск, фильтры, личный кабинет, формы, калькуляторы.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Интеграции с внешними сервисами: </span>
                                                        </span>
                                                    Подключение систем аналитики и других инструментов.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог:</span>
                                                        </span>
                                                    Полностью работающий сайт, готовый к тестированию.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '6',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Тестирование и контроль качества</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Проверка работы всех функций и корректности отображения сайта на разных устройствах и браузерах.
                                        </div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Функциональное тестирование: </span>
                                                        </span>
                                                    Проверка всех функций — форм, кнопок, работы фильтров и поиска.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Кроссбраузерное тестирование: </span>
                                                        </span>
                                                    Тестирование корректности отображения в популярных браузерах (Chrome, Firefox, Safari, Edge).
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Тестирование адаптивности: </span>
                                                        </span>
                                                    Проверка сайта на мобильных устройствах, планшетах и десктопах.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Оптимизация скорости загрузки: </span>
                                                        </span>
                                                    Проверка скорости загрузки сайта и оптимизация изображений, скриптов и CSS.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог: </span>
                                                        </span>
                                                    Протестированный сайт, готовый к запуску.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '7',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Запуск сайта</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Публикация сайта на хостинге и проверка его работы в боевых условиях.
                                        </div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Перенос на продакшн-сервер:</span>
                                                        </span>
                                                    Загрузка файлов на сервер, настройка домена и базы данных.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Проверка работы на сервере:</span>
                                                        </span>
                                                    Проверяем, что все функции работают корректно, и сайт доступен для пользователей.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Настройка SEO-метаданных: </span>
                                                        </span>
                                                    Добавление мета-тегов, alt-тегов для изображений, настройка карты сайта и файла robots.txt.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог: </span>
                                                        </span>
                                                    Рабочий сайт, который доступен для пользователей.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color:`${props.textColor}`}} rotate={isActive ? 90 : 0} />}
                        collapsible="header"
                        items={[
                            {
                                key: '8',
                                label: <span className="title_collapse" style={{color:`${props.textColor}`, fontWeight: 'bold' }}>Поддержка и развитие (при условии дальнейшего сотрудничества)</span>,
                                children:
                                    <div className="block_description" style={{ background: 'transparent', padding: '10px' }}>
                                        <div className="desc_steps">Обеспечение бесперебойной работы сайта и его адаптация к изменениям в бизнесе.
                                        </div>
                                        <ul className="list_steps_all">
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Мониторинг производительности:</span>
                                                        </span>
                                                    Отслеживание работы сайта, исправление возможных ошибок и поддержание безопасности.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Обновление контента:</span>
                                                        </span>
                                                    Помощь в обновлении и добавлении контента, обучение работе с админкой (если она имеется).
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Анализ и улучшение UX: </span>
                                                        </span>
                                                    Сбор отзывов пользователей и анализа поведения на сайте, внесение изменений для повышения удобства.
                                                </div>
                                            </li>
                                            <li>

                                                <div className="flex_list">
                                                        <span className="name_list_steps">
                                                            <CheckCircleFilled />
                                                            <span> Итог: </span>
                                                        </span>
                                                    Стабильная работа сайта, регулярные обновления и улучшения для соответствия бизнес-задачам клиента.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>,
                            },
                        ]}
                    />
                </Space>
                <div className="description_check_cteps" style={{color:`${props.textColor}`}}>
                    В дальнейшем за каждым этапом вы сможете наблюдать в личном кабинете. Там же будут формироваться отчеты по каждому этапу.
                </div>
            </div>
        </div>
    );
};

export default Portfolio;