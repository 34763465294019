import react_icon from '../../../images/iconTechnology/react_icon.svg';
import redux_icon from '../../../images/iconTechnology/react_icon.svg';
import js_icon from '../../../images/iconTechnology/js_icon.svg';
import gulp_icon from '../../../images/iconTechnology/gulp_icon.svg';
import html_icon from '../../../images/iconTechnology/html_icon.svg';
import bootstrap_icon from '../../../images/iconTechnology/bootstrap_icon.svg';
import sass_icon from '../../../images/iconTechnology/sass_icon.svg';

const weblist = [
    {
        name: 'React',
        image: react_icon,
        id: 1
    },
    {
        name: 'Redux',
        image: redux_icon,
        id: 2
    },
    {
        name: 'JavaScript',
        image: js_icon,
        id: 3
    },
    {
        name: 'Gulp',
        image: gulp_icon,
        id: 4
    },
    {
        name: 'HTML5',
        image: html_icon,
        id: 5
    },
    {
        name: 'Bootstrap',
        image: bootstrap_icon,
        id: 6
    },
    {
        name: 'Sass',
        image: sass_icon,
        id: 7
    }
]

export default weblist;