import React from 'react';
import {Col, Row} from "antd";

const CompListWebDesign = (props) => {
    console.log(props.list_web_design)
    return (
        <div className='list_web'>
            <div className="name_list_web" style={{
                color:`${props.textColor === '#fff' ? '#000' : '#fff'}`
            }}>
                <p style={{
                    background:`${props.bgColor === '#fff' ? '#000' : '#fff'}`
                }}>ИНСТРУМЕНТЫ ВЕБ-ДИЗАЙНА</p>
            </div>
            {props.list_web_design.map(el => (
                <Row>
                    <Col key={el.id} className="block_one_list__technology" style={{border: `2px solid ${props.bgColor === '#fff' ? '#292929' : '#fff'}`}}>
                        <img src={el.image} alt={el.image}/>
                        <div className="desc_image" style={{color:`${props.textColor}`}}>
                            {el.name}
                        </div>
                    </Col>
                </Row>

            ))}
        </div>
    );
};

export default CompListWebDesign;