import React from 'react';
import './section3.css';
import {Fade} from "react-awesome-reveal";
import Card from "./card/card";
import CardBottom from "./card/card_bottom";
import cardWebDevCorp from '../../../videos/card_web_dev.mp4'
import cardWebDesign from '../../../videos/card_web_design.mp4'

const Section3 = (props) => {





    return (
        <div id="services" className="block_three_section services_pc" data-cursor-exclusion>
            <div className="title_three_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                <Fade delay={0} cascade damping={0.05} style={{borderBottom:`2px solid ${props.textColor}`,transition:'1s all ease'}}>
                    Услуги
                </Fade>
            </div>
            <div className="container">
                <div className="block_about">
                    <Fade duration={2000}>
                        <div className="cards_about">
                            <Card
                                bgColor={props.bgColor}
                                textColor={props.textColor}
                                cards={props.cards}
                                setCursorUslugi={props.setCursorUslugi}
                            />
                        </div>
                    </Fade>
                    <Fade duration={2000}>
                        <div className="cards_about">
                            <CardBottom
                                bgColor={props.bgColor}
                                textColor={props.textColor}
                                cardsBottom={props.cardsBottom}
                                setCursorUslugi={props.setCursorUslugi} />
                        </div>
                    </Fade>

                </div>
            </div>
        </div>
    );
};

export default Section3;