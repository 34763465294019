import React from 'react';
import {Fade} from "react-awesome-reveal";
import './section4.css'
import weblist from "./weblist";
import list_web_design from "./card_web_design/list_web_design";
import ListWebTechnology from "./card_web_technology/list_web_technology";
import CompListWebDesign from "./card_web_design/comp_list_web_design";
import {Typography} from "antd";
const { Text } = Typography;

const Section4 = (props) => {




    return (
        <div id='about' className="block_thour_section" data-cursor-exclusion >
            <div className="title_thour_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                <Fade delay={0} cascade damping={0.05} style={{borderBottom:`2px solid ${props.textColor}`,transition:'1s all ease'}}>
                    О нас
                </Fade>
            </div>
            <div className="container">
                <div className="title_block__two" >
                    <p className='left_margin_title__block_two'>
                        <Fade cascade damping={0.02} style={{color:`${props.textColor}`}}>
                            Web development studio!
                        </Fade>
                    </p>
                    <p className="desc_block_two">
                        <Fade
                            cascade damping={0.02} delay={600} style={{color:`${props.textColor}`, whiteSpace:'normal!important'}}>
                            Наша команда профессионалов готова воплотить в жизнь любые проекты.
                        </Fade>
                    </p>
                    <p className="instruments_title">
                        <Fade style={{color:`${props.textColor}`}}
                            cascade damping={0.02} delay={600}>
                            Инструменты которые мы используем
                        </Fade>
                    </p>
                </div>
                <div className="block_web_technology">
                    <Fade duration={1000}>
                        <ListWebTechnology
                            bgColor={props.bgColor}
                            textColor={props.textColor}
                            weblist={weblist}/>
                    </Fade>
                    <Fade duration={1000} delay={300}>
                        <CompListWebDesign
                            bgColor={props.bgColor}
                            textColor={props.textColor}
                            list_web_design={list_web_design}/>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default Section4;